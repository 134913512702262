<script>
  import { DateTime } from "luxon";
  import flatPickr from "vue-flatpickr-component";
  import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
  import "flatpickr/dist/flatpickr.css";
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
  import { overlay, notificationMethods } from "@/state/helpers";
  import Layout from "@/layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import EmptyTableResult from "@/components/empty-table-result";
  import TaskForm from "@/components/task-form";
  import appConfig from "@/../app.config";
  import Swal from "sweetalert2";
  import { callUrl, getUserData } from '@/helpers/api-apolo';
  import { saError } from '@/helpers/global-functions';
  import * as helpers from '@/helpers/helper-functions';

  export default {
    page: {
      title: "Tarefas",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        title: "Tarefas",
        items: [{
            text: "CRM",
            href: "/"
          },
          {
            text: "Tarefas",
            active: true
          },
        ],
        rangeDateConfig: {
          mode: "range",
          altInput: true,
          altFormat: "d M \\d\\e Y",
          dateFormat: "Y-m-d",
          locale: Portuguese
        },
        typeNames: {
          call: 'Ligar',
          email: 'E-mail',
          meeting: 'Reunião',
          whatsapp: 'Whatsapp',
          task: 'Tarefa',
          deadline: 'Prazo',
          lunch: 'Almoço'
        },
        userId: '',
        filters: {
          query: '',
          type: '',
          status: '',
          date: null
        },
        showFilter: false,
        page: 1,
        perPage: 10,
        dataList: [],
        taskFormOpened: false,
        activeTask: {
            id: '',
            attendants: [],
            business_id: '',
            description: '',
            estimated_closing_datetime: '',
            type: '',
            status: ''
        }
      }
    },
    components: {
      Layout,
      PageHeader,
      Multiselect,
      EmptyTableResult,
      TaskForm,
      flatPickr
    },
    computed: {
      pages() {
        if (this.page == 1 && !this.dataList.length) {
          return [];
        }
        if (this.page == 1 && this.dataList.length < this.perPage) {
          return [1];
        }
        if (this.page == 1 && this.dataList.length == this.perPage) {
          return [1, 2];
        }
        if (this.page > 1 && (!this.dataList.length || this.dataList.length < this.perPage)) {
          return [this.page - 1, this.page];
        }
        return [this.page - 1, this.page, this.page + 1];
      }
    },
    watch: {
      page() {
        this.fetchData();
      }
    },
    methods: {
      ...overlay,
      ...helpers,
      ...notificationMethods,
      translateType(value) {
        return this.typeNames[value]||value;
      },
      async deletedata(item) {
        Swal.fire({
            title: "Você tem certeza que deseja remover esta tarefa?",
            text: "Não será possível reverter esta ação!",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Sim, remover!",
            cancelButtonText: "Cancelar",
            buttonsStyling: false,
            customClass: {
              actions: 'd-flex justify-content-center',
              confirmButton: 'btn btn-success flex-grow-1',
              cancelButton: 'btn btn-outline-dark flex-grow-1'
            }
        }).then(async (result) => {
            if (result.value) {
            try {
                this.changeShowOverlay({show: true});
                await callUrl({}, `/business/${item.business_id}/task/${item.id}`, 'delete')
                this.changeShowOverlay({show: false});
                Swal.fire("Sucesso!", "Tarefa removida com sucesso", "success");
                this.dataList = this.dataList.filter(task => task.id !== item.id);
            } catch (error) {
                this.changeShowOverlay({show: false});
                saError(error.message);
            }
            }
        });
      },
      search() {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.fetchData();
        }
      },
      async fetchData(firstTime = false) {
        try {
          if (!firstTime) {
            this.setRefreshTaskCount({status: true})
          }
          this.changeShowOverlay({show: true});
          this.dataList = [];
          const params = [`page=${this.page}`, `limit=${this.perPage}`, `order=status:1`];
          if (this.filters.query.length > 2) {
            params.push(`query=${this.filters.query}`);
          }
          if (this.filters.type.length > 0) {
            params.push(`type=${this.filters.type}`);
          }
          if (this.filters.status.length > 0) {
            params.push(`status=${this.filters.status}`);
          }
          if (this.filters.date && this.filters.date.length > 0) {
            const dates = this.filters.date.split(' ');
            params.push(`estimated_closing_date_begin=${dates[0]}T00:00:00`);
            params.push(`estimated_closing_date_end=${dates[2]}T23:59:59`);
          }
          const dataM = await callUrl({}, `/user/${this.userId}/task?${params.join('&')}`, 'get');
          if (dataM && dataM.length) {
            this.dataList = dataM;
            this.generateDelayedProperty();
          }
        } catch (error) {
          console.log(error.message);
        }
        this.changeShowOverlay({show: false});
      },
      updateData() {
        this.taskFormOpened = false;
        this.fetchData();
      },
      editData(item) {
        this.activeTask     = JSON.parse(JSON.stringify(item));
        this.taskFormOpened = true;
      },
      async closeTask(item) {
        Swal.fire({
            title: "Você tem certeza que deseja fechar esta tarefa?",
            text: "Não será possível reverter esta ação!",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sim, fechar!",
            reverseButtons: true,
            buttonsStyling: false,
            customClass: {
              actions: 'd-flex justify-content-center',
              confirmButton: 'btn btn-success flex-grow-1',
              cancelButton: 'btn btn-outline-dark flex-grow-1'
            }
        }).then(async (result) => {
            if (result.value) {
                try {
                    this.changeShowOverlay({show: true});
                    await callUrl({}, `/business/${item.business_id}/task/${item.id}/close`, 'post')
                    this.changeShowOverlay({show: false});
                    Swal.fire("Sucesso!", "Tarefa fechada com sucesso", "success");
                    this.fetchData();
                } catch (error) {
                    this.changeShowOverlay({show: false});
                    saError(error.message);
                }
            }
        });
      },
      generateDelayedProperty() {
        this.dataList = this.dataList.map(task => {
          task.delayed        = false;
          task.closeToWinning = false;
          if (task.status === 'Aberto' && task.estimated_closing_datetime) {
            task.delayed = DateTime.fromISO(task.estimated_closing_datetime) < DateTime.now();
            if (!task.delayed) {
              task.closeToWinning = DateTime.fromISO(task.estimated_closing_datetime) < DateTime.now().plus({ days: 1 });
            }
          }
          return task;
        })
      },
      clearFilter() {
        this.filters = {
          query: '',
          type: '',
          status: '',
          date: null
        };
        this.search();
      },
    },
    mounted() {
        const userData = getUserData();
        this.userId    = userData.user_id;
        this.fetchData(true);
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="customFieldList">

          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Tarefas</h5>
              <div class="flex-shrink-0 me-5">
                <router-link :to="{ name: 'task-list-by-calendar' }">Versão Calendário</router-link>
              </div>
              <div class="flex-shrink-0">
                <button type="button" class="btn btn-info" data-bs-toggle="offcanvas" href="#offcanvasExample" @click.prevent="showFilter = true">
                  <i class="ri-filter-3-line align-bottom me-1"></i> Filtros
                </button>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div>
              <div class="table-responsive table-card">
                <table class="table align-middle table-nowrap">
                  <thead class="text-muted">
                    <tr>
                      <th class="sort text-uppercase">ID</th>
                      <th class="sort text-uppercase">Negócio</th>
                      <th class="sort text-uppercase">Tarefa</th>
                      <th class="sort text-uppercase">Tipo</th>
                      <th class="sort text-uppercase">Criado em</th>
                      <th class="sort text-uppercase">Vence em</th>
                      <th class="sort text-uppercase">Status</th>
                      <th class="sort text-uppercase">Ações</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="(item, index) of dataList" :key="index" :class="{'text-danger': item.delayed, 'text-warning': item.closeToWinning}">
                      <td>{{item.code||item.id}}</td>
                      <td><router-link :to="{ name: 'deal-details', params: { id: item.business_id } }" :class="{'text-danger': item.delayed, 'text-warning': item.closeToWinning}">{{item.business_id}}</router-link></td>
                      <td>
                        <div class="d-flex align-items-center">
                          {{limitString(item.description, 18)}}
                        </div>
                      </td>
                      <td>{{translateType(item.type)}}</td>
                      <td>{{translateDateOnly(item.created_at)}} <small class="text-muted">{{translateTimeOnly(item.created_at)}}</small></td>
                      <td v-if="item?.estimated_closing_datetime?.toString()?.length > 0">
                        {{translateDateOnly(item.estimated_closing_datetime)}} <small class="text-muted">{{translateTimeOnly(item.estimated_closing_datetime)}}</small>
                        <br><span v-if="item.delayed" class="badge rounded-pill badge-soft-danger">em atraso</span>
                        <span v-if="item.closeToWinning" class="badge rounded-pill badge-soft-warning">próximo do prazo</span>
                      </td>
                      <td v-if="!item.estimated_closing_datetime">-</td>
                      <td><span :class="['badge', {'badge-soft-warning': item.status === 'Aberto'}, {'badge-soft-success': item.status === 'Fechado'}]">{{item.status}}</span></td>
                      <td>
                        <div v-if="item.status === 'Aberto'" class="dropdown">
                          <button class="btn btn-soft-secondary btn-sm dropdown" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="ri-more-fill align-middle"></i>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <a class="dropdown-item" @click.prevent="editData(item)" style="cursor:pointer">
                                <i class="ri-pencil-fill align-bottom me-2 text-muted"></i>Editar
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" @click.prevent="closeTask(item)" style="cursor:pointer">
                                <i class="mdi mdi-archive-plus align-bottom me-2 text-muted"></i>Fechar
                              </a>
                            </li>
                            <li class="dropdown-divider"></li>
                            <li>
                              <a class="dropdown-item remove-item-btn" @click.prevent="deletedata(item)" style="cursor:pointer">
                                <i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Remover
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <EmptyTableResult v-show="dataList.length == 0" />

              </div>
              <div class="d-flex justify-content-end mt-4">
                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev" href="#" @click="--page" v-if="page > 1">Anterior</a>
                  <a class="page-item pagination-prev disabled" href="#" v-if="dataList.length && page == 1" @click.prevent="">Anterior</a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li v-for="pageNumber in pages" :key="'page' + pageNumber" @click="page = pageNumber"
                      :class="{active: pageNumber == page, disabled: pageNumber != page}">
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="++page" v-if="dataList.length == perPage">Próxima</a>
                  <a class="page-item pagination-next disabled" href="#" @click.prevent="" v-if="dataList.length && dataList.length < perPage">Próxima</a>
                </div>
              </div>

              <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" v-show="showFilter" ref="modalFilter">
                <div class="offcanvas-header bg-light">
                  <h5 class="offcanvas-title" id="offcanvasExampleLabel">Filtros de tarefas</h5>
                  <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <form action="" class="d-flex flex-column justify-content-end h-100">
                  <div class="offcanvas-body">
                    <div class="mb-4">
                      <label for="filter-query" class="form-label text-muted text-uppercase fw-semibold mb-3">Pesquisa</label>
                      <div class="search-box">
                        <input id="filter-query" type="text" class="form-control search" v-model="filters.query" placeholder="Busca por algum termo...">
                        <i class="ri-search-line search-icon"></i>
                      </div>
                    </div>

                    <div class="mb-4">
                      <label for="datepicker-range" class="form-label text-muted text-uppercase fw-semibold mb-3">Vencimento entre</label>
                      <flat-pickr placeholder="Período de vencimento" v-model="filters.date" :config="rangeDateConfig" class="form-control flatpickr-input" id="datepicker-range"></flat-pickr>
                    </div>

                    <div class="mb-4 input-light">
                      <label for="filter-type" class="form-label text-muted text-uppercase fw-semibold mb-3">Tipo de Tarefa</label>
                      <Multiselect id="filter-type" class="form-control" v-model="filters.type" :close-on-select="true" :searchable="true"
                        :create-option="true"
                        :options="[{ value: '', label: 'Todos tipos' }].concat(Object.keys(typeNames).map(key => ({value:key, label:typeNames[key]})))" />
                    </div>

                    <div class="mb-4 input-light">
                      <label for="filter-status" class="form-label text-muted text-uppercase fw-semibold mb-3">Status de Tarefa</label>
                      <Multiselect id="filter-status" class="form-control" v-model="filters.status" :close-on-select="true" :searchable="true"
                        :create-option="true"
                        :options="[{ value: '', label: 'Todos status' }, { value: 'Aberto', label: 'Abertos' }, { value: 'Fechado', label: 'Fechados' }]" />
                    </div>
                  </div>
                  <div class="offcanvas-footer border-top p-3 text-center hstack gap-2">
                    <button class="btn btn-light w-100" @click.prevent="clearFilter" data-bs-dismiss="offcanvas">Limpar</button>
                    <button class="btn btn-success w-100" @click.prevent="search" data-bs-dismiss="offcanvas">Filtrar</button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </Layout>

    <TaskForm
        :opened="taskFormOpened"
        :task="activeTask"
        @updated="updateData"
        @close="taskFormOpened = false"
    />
</template>
